<template>
  <div class="campaign_regist">
    <SideVar />
    <RightColumnOutline>
      <PageTitle>Create Campaign</PageTitle>
      <div
        class="flex flex-wrap items-center pb-8 mb-8 border-b border-gray-400 border-opacity-20"
      >
        <div class="w-1/3 pl-18 text-left">
          <span class="text-sm font-medium text-gray-100"
            >キャンペーン名 *
          </span>
        </div>
        <div class="w-2/3 px-4 max-w-xl">
          <div class="flex flex-wrap items-center -mx-3 w-full px-3">
            <input
              class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
              type="text"
              v-model="campaign_name"
              placeholder="xxxx記念キャンペーン"
            />
          </div>
        </div>
      </div>
      <div
        class="flex flex-wrap items-center pb-8 mb-8 border-b border-gray-400 border-opacity-20"
      >
        <div class="w-1/3 pl-18 text-left">
          <span class="text-sm font-medium text-gray-100"
            >開催するキャンペーンの種類 *
          </span>
        </div>
        <div class="w-2/3 px-4 max-w-xl">
          <div
            class="relative block px-3 w-full text-sm font-medium border border-gray-400 hover:border-white focus-within:border-green-500 rounded-lg"
          >
            <span
              class="absolute top-1/2 right-0 mr-5 transform -translate-y-1/2"
            >
              <i class="pi pi-chevron-down"></i>
            </span>
            <select
              class="w-full py-4 text-gray-50 placeholder-gray-300 appearance-none bg-transparent outline-none"
              id="category"
              v-model="category"
            >
              <option
                class="bg-gray-500"
                value="FOLLOW_AND_RETWEET"
                v-if="plan != 'FREE'"
              >
                フォロー＆リポスト
              </option>
              <option
                class="bg-gray-500"
                value="HASHTAG"
                v-if="plan == 'SILVER' || plan == 'GOLD'"
              >
                ハッシュタグキャンペーン
              </option>
              <option
                class="bg-gray-500"
                value="INSTANT_WIN"
                v-if="plan == 'GOLD'"
              >
                インスタントウィン
              </option>
            </select>
          </div>
        </div>
      </div>
      <div
        class="flex flex-wrap items-center mb-4 border-gray-400 border-opacity-20"
        v-if="condition_flag.keyword"
      >
        <div class="w-1/3 pl-18 text-left">
          <span class="text-sm font-medium text-gray-100"
            >キーワード
            <i
              class="pi pi-question-circle mr-1"
              v-tippy="{
                content:
                  'ハッシュタグ必須。ハッシュタグは１つのみ登録してください。',
              }"
            ></i
            >*
          </span>
        </div>
        <div class="w-1/3 px-4 max-w-xl">
          <input
            class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
            type="text"
            v-model="keyword"
            placeholder="#xxxx記念"
          />
        </div>
        <div class="w-1/4 px-4 max-w-xl">
          <DoButton
            :clickFunction="checkKeywordPromise"
            :values="{}"
            :disabled="!keyword || keyword_check"
            >キーワードチェック</DoButton
          >
        </div>
      </div>
      <div
        class="flex flex-wrap items-center -mx-4 pb-8 mb-8 border-b border-gray-400 border-opacity-20"
        v-if="condition_flag.keyword"
      >
        <div class="w-1/3 px-4 pb-4">
          <span class="text-sm font-medium text-gray-100"></span>
        </div>
        <div class="w-2/3 px-4">
          <div
            v-if="keyword_check_result"
            class="max-w-xl text-gray-100 text-left"
          >
            {{ keyword_check_result }}
          </div>
        </div>
      </div>
      <div
        class="flex flex-wrap items-center pb-8 mb-8 border-b border-gray-400 border-opacity-20"
        v-if="nouse_in_this_version"
      >
        <div class="w-1/3 pl-18 text-left">
          <span class="text-sm font-medium text-gray-100"
            >キャンペーン概要</span
          >
        </div>
        <div class="w-2/3 px-4 max-w-xl">
          <input
            class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
            id="abstract"
            type="text"
            v-model="abstract"
            placeholder="概要：xxxのサービス開始1周年を記念したキャンペーン。総額yyyyyドル分のzzzzをポイントの高い順にプレゼント"
          />
        </div>
      </div>
      <div
        class="flex flex-wrap items-center pb-8 mb-8 border-b border-gray-400 border-opacity-20"
        v-if="condition_flag.detail"
      >
        <div class="w-1/3 pl-18 text-left">
          <span class="text-sm font-medium text-gray-100"
            >キャンペーン詳細</span
          >
        </div>
        <div class="w-2/3 px-4 max-w-xl">
          <textarea
            class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
            rows="4"
            v-model="detail"
            placeholder="メモとしてご利用ください"
          />
        </div>
      </div>
      <div
        class="flex flex-wrap items-center pb-8 mb-8 border-b border-gray-400 border-opacity-20"
        v-if="condition_flag.start_time && condition_flag.end_time"
      >
        <div class="w-1/3 pl-18 text-left">
          <span class="text-sm font-medium text-gray-100"
            >キャンペーン期間<i
              class="pi pi-question-circle m-1"
              v-tippy="{
                content:
                  '現在時刻の１時間後以降で、１週間以内の期間を指定してください。',
              }"
            ></i>
            *
          </span>
        </div>
        <div class="w-2/3 px-4 max-w-xl flex -mx-3">
          <div class="relative block px-3 w-full text-gray-100">
            <Datepicker v-model="date" range :min-date="min_date" />
          </div>
        </div>
      </div>
      <div
        class="flex flex-wrap items-center pb-8 mb-8 border-b border-gray-400 border-opacity-20"
        v-if="!condition_flag.start_time && condition_flag.end_time"
      >
        <div class="w-1/3 pl-18 text-left">
          <span class="text-sm font-medium text-gray-100"
            >募集期限<i
              class="pi pi-question-circle mx-1"
              v-tippy="{
                content: '募集期限には当日〜10日後まで設定可能です',
              }"
            ></i>
            *
          </span>
        </div>
        <div class="w-2/3 px-4 max-w-xl flex -mx-3">
          <div class="relative block px-3 w-full text-gray-100">
            <Datepicker
              v-model="date[1]"
              :min-date="min_date"
              :max-date="max_date"
            />
          </div>
        </div>
      </div>
      <div
        class="flex flex-wrap items-center pb-8 mb-8 border-b border-gray-400 border-opacity-20"
        v-if="condition_flag.amount"
      >
        <div class="w-1/3 pl-18 text-left">
          <span class="text-sm font-medium text-gray-100">当選人数 * </span>
        </div>
        <div class="w-1/3 px-4 max-w-xl">
          <input
            class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
            type="number"
            v-model="reword_amount"
            placeholder="10"
          />
        </div>
      </div>
      <div
        class="flex flex-wrap items-center pb-8 mb-8 border-b border-gray-400 border-opacity-20"
        v-if="condition_flag.estimated_participants_count"
      >
        <div class="w-1/3 pl-18 text-left">
          <span class="text-sm font-medium text-gray-100"
            >想定参加人数
            <i
              class="pi pi-question-circle mr-1"
              v-tippy="{
                content: '当選確率を決定するために利用します',
              }"
            ></i
            >*
          </span>
        </div>
        <div class="w-1/3 px-4 max-w-xl">
          <input
            class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
            type="number"
            v-model="estimated_participants_count"
            placeholder="10000"
          />
        </div>
      </div>
      <div
        class="flex flex-wrap items-center pb-8 mb-8 border-b border-gray-400 border-opacity-20"
        v-if="condition_flag.distribution"
      >
        <div class="w-1/3 pl-18 text-left">
          <span class="text-sm font-medium text-gray-100">報酬の分配方法</span>
        </div>
        <div class="w-2/3 px-4 max-w-xl">
          <div
            class="relative block px-3 w-full text-sm font-medium border border-gray-400 hover:border-white focus-within:border-green-500 rounded-lg"
          >
            <span
              class="absolute top-1/2 right-0 mr-5 transform -translate-y-1/2"
            >
              <i class="pi pi-chevron-down"></i>
            </span>
            <select
              class="w-full py-4 text-gray-50 placeholder-gray-50 appearance-none bg-transparent outline-none"
              id="distribution_type"
              v-model="distribution_type"
            >
              <option class="bg-gray-500" value="1">
                獲得ポイントに基づくルールベースの分配
              </option>
              <option class="bg-gray-500" value="2">
                獲得ポイントに基づく抽選
              </option>
              <option class="bg-gray-500" value="3">
                その他（要望に合わせて対応いたします）
              </option>
            </select>
          </div>
        </div>
      </div>
      <div v-if="category == 'HASHTAG'">
        <DoButton
          :clickFunction="createCampaignPromise"
          :values="{}"
          :disabled="!filled"
          >登録</DoButton
        >
      </div>
      <div v-if="category == 'FOLLOW_AND_RETWEET'">
        <DoButton
          :clickFunction="createCampaignPromise"
          :values="{}"
          :disabled="!follow_and_retweet_filled"
          >登録</DoButton
        >
      </div>
      <div v-if="category == 'INSTANT_WIN'">
        <DoButton
          :clickFunction="createCampaignPromise"
          :values="{}"
          :disabled="!instant_win_filled"
          >登録</DoButton
        >
      </div>
    </RightColumnOutline>
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import checks from "../assets/mixins/Checks";

import api from "../api/api.js";
import { ref, onMounted, inject } from "vue";

export default {
  name: "Campaign_regist",
  mixins: [checks],
  components: {
    Datepicker,
  },
  setup() {
    let date = ref();
    let min_date = ref();
    let max_date = ref();
    let today_start_dummy = ref();
    const toast = inject("toast");
    let small_campaign_count = ref(0);
    let big_campaign_count = ref(0);

    // キャンペーン実施可能数を確認する
    const checkCount = function () {
      api
        .getMyOrganizer()
        .then((response) => {
          small_campaign_count.value = response.data.small_campaign_count;
          big_campaign_count.value = response.data.big_campaign_count;
          if (
            small_campaign_count.value == 0 &&
            big_campaign_count.value == 0
          ) {
            // 何もできることがない
            toast.show(
              "キャンペーン開催可能数が０です。\
              利用回数が不足している場合はプランのアップグレードをご検討ください",
              {
                type: "error",
                position: "top-right",
                duration: 5000,
              }
            );
          }
        })
        .catch((error) => {
          toast.show("キャンペーン利用可能数の取得に失敗しました", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
          console.log("error: ", error);
        });
    };

    onMounted(() => {
      // キャンペーン可能数を計算する処理
      checkCount();

      // 日付の最小〜最大を計算する処理
      const today = new Date();
      today_start_dummy.value = today;
      let startDate = new Date(new Date().setDate(today.getDate() + 1));
      startDate.setHours(21, 0, 0, 0);
      let endDate = new Date(new Date().setDate(startDate.getDate() + 7));
      endDate.setHours(21, 0, 0, 0);
      date.value = [startDate, endDate];

      min_date.value = new Date(new Date().setHours(today.getHours() + 1));
      max_date.value = new Date(new Date().setDate(today.getDate() + 10));
      max_date.value.setHours(23, 59, 0, 0);
    });

    return {
      date,
      min_date,
      max_date,
      today_start_dummy,
      small_campaign_count,
      big_campaign_count,
    };
  },
  data() {
    return {
      campaign_name: null,
      category: null,
      keyword: null,
      keyword_check: null,
      keyword_count: null,
      keyword_check_result: null,
      abstract: null,
      detail: null,
      reword_amount: null,
      reword_currency: null,
      distribution_type: null,
      nouse_in_this_version: false,

      // For Instant Win
      estimated_participants_count: null,
    };
  },
  computed: {
    filled() {
      return (
        this.campaign_name &&
        this.keyword_check &&
        this.category &&
        this.keyword &&
        this.small_campaign_count + this.big_campaign_count > 0
      );
    },
    follow_and_retweet_filled() {
      return (
        this.campaign_name &&
        this.category &&
        this.reword_amount &&
        this.date[1] &&
        // this.twitter_account_check &&
        // (this.tweet_url_check || (!this.tweet_url && !this.tweet_url_check)) &&
        this.small_campaign_count + this.big_campaign_count > 0
      );
    },
    instant_win_filled() {
      return (
        this.campaign_name &&
        this.category &&
        this.reword_amount &&
        this.date[1] &&
        // this.twitter_account_check &&
        this.estimated_participants_count &&
        this.big_campaign_count > 0
      );
    },
    condition_flag: function () {
      // init
      let return_dict = {
        keyword: false,
        abstruct: false,
        detail: false,
        distribution_type: false,
        amount: false,
        currency_id: false,
        start_time: false,
        end_time: false,
        twitter_account: false,
        twitter_url: false,
        estimated_participants_count: false,
      };
      if (this.category == null) {
        return return_dict;
      } else if (this.category == "HASHTAG") {
        return_dict = {
          keyword: true,
          abstruct: true,
          detail: true,
          distribution_type: false,
          amount: false,
          currency_id: false,
          start_time: true,
          end_time: true,
          twitter_account: false,
          twitter_url: false,
          estimated_participants_count: false,
        };
        return return_dict;
      } else if (this.category == "FOLLOW_AND_RETWEET") {
        return_dict = {
          keyword: false,
          abstruct: false,
          detail: false,
          distribution_type: false,
          amount: true,
          currency_id: false,
          start_time: false,
          end_time: true,
          twitter_account: false,
          twitter_url: false,
          estimated_participants_count: false,
        };
        return return_dict;
      } else if (this.category == "INSTANT_WIN") {
        return_dict = {
          keyword: false,
          abstruct: false,
          detail: false,
          distribution_type: false,
          amount: true,
          estimated_participants_count: true,
          currency_id: false,
          start_time: true,
          end_time: true,
          twitter_account: false,
          twitter_url: false,
        };
        return return_dict;
      } else {
        return return_dict;
      }
    },
    plan: function () {
      return this.$store.getters["subscribePlan"];
    },
  },
  watch: {
    keyword(value) {
      this.keyword_check = false;
      this.keyword_check_result = "";
      if (value.indexOf("#")) {
        this.keyword = "#" + value;
      }
    },
    date(value) {
      if (value != null) {
        var diffTime = value[1].getTime() - value[0].getTime();
        var diffDay = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        if (diffDay > 7) {
          this.date = null;
          this.$toast.show("キャンペーン期間は１週間以内で設定してください", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
        }
      }
    },
  },
  methods: {
    createCampaignPromise: function () {
      return new Promise((resolve) => {
        let status = 1;
        let start = this.date[0];
        let end = this.date[1];
        if (this.category == "FOLLOW_AND_RETWEET") {
          start = this.today_start_dummy;
        }
        api
          .createCampaign({
            status: status,
            keyword: this.keyword,
            start_time: start,
            end_time: end,
            detail: this.detail,
            campaign_name: this.campaign_name,
            distribution_type: this.distribution_type,
            amount: this.reword_amount,
            currency_id: this.reword_currency,
            category: this.category,
          })
          .then((response) => {
            if (this.category == "INSTANT_WIN") {
              api
                .postInstantWin({
                  campaign_id: response["data"]["campaign_id"],
                  total_win: this.reword_amount,
                  est_participant: this.estimated_participants_count,
                  remain_win: this.reword_amount,
                  prob: this.reword_amount / this.estimated_participants_count,
                })
                .then(() => {
                  this.$toast.show("キャンペーンの登録が完了しました", {
                    type: "success",
                    position: "top-right",
                    duration: 3000,
                  });
                  this.$router.replace("/campaigns");
                })
                .catch((error) => {
                  this.$toast.show("キャンペーンの登録に失敗しました", {
                    type: "error",
                    position: "top-right",
                    duration: 5000,
                  });
                  console.log("error: ", error);
                });
              resolve("resolved");
            }
            // hashtag
            if (
              this.category == "HASHTAG" ||
              this.category == "FOLLOW_AND_RETWEET"
            ) {
              this.$toast.show("キャンペーンの登録が完了しました", {
                type: "success",
                position: "top-right",
                duration: 3000,
              });
              this.$router.replace("/campaigns");
              resolve("resolved");
            }
          })
          .catch((error) => {
            this.$toast.show("キャンペーンの登録に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
  },
};
</script>
