<template>
  <div class="campaign_modify">
    <div class="">
      <div>
        <SideVar />
        <RightColumnOutline>
          <PageTitle>Modify Campaign</PageTitle>
          <div
            class="flex flex-wrap items-center pb-8 mb-8 border-b border-gray-400 border-opacity-20"
          >
            <div class="w-1/3 pl-18 text-left">
              <span class="text-sm font-medium text-gray-100"
                >キャンペーン名 *
              </span>
            </div>
            <div class="w-2/3 px-4 max-w-xl">
              <div class="flex flex-wrap items-center -mx-3 w-full px-3">
                <input
                  class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                  type="text"
                  v-model="campaign_name"
                  placeholder="xxxx記念キャンペーン"
                />
              </div>
            </div>
          </div>
          <div
            class="flex flex-wrap items-center pb-8 mb-8 border-b border-gray-400 border-opacity-20"
          >
            <div class="w-1/3 pl-18 text-left">
              <span class="text-sm font-medium text-gray-100"
                >開催するキャンペーンの種類 *
              </span>
            </div>
            <div class="w-2/3 px-4 max-w-xl">
              <div
                class="relative block px-3 w-full text-sm font-medium border border-gray-400 hover:border-white focus-within:border-green-500 rounded-lg"
              >
                <span
                  class="absolute top-1/2 right-0 mr-5 transform -translate-y-1/2"
                >
                  <i class="pi pi-chevron-down"></i>
                </span>
                <select
                  class="w-full py-4 text-gray-50 placeholder-gray-300 appearance-none bg-transparent outline-none"
                  id="category"
                  v-model="category"
                  :disabled="true"
                >
                  <option
                    class="bg-gray-500"
                    value="FOLLOW_AND_RETWEET"
                    v-if="plan != 'FREE'"
                  >
                    フォロー＆リツイート
                  </option>
                  <option
                    class="bg-gray-500"
                    value="HASHTAG"
                    v-if="plan == 'SILVER' || plan == 'GOLD'"
                  >
                    ハッシュタグキャンペーン
                  </option>
                  <option
                    class="bg-gray-500"
                    value="INSTANT_WIN"
                    v-if="plan == 'GOLD'"
                  >
                    インスタントウィン
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div
            class="flex flex-wrap items-center mb-4 border-gray-400 border-opacity-20"
            v-if="condition_flag.keyword"
          >
            <div class="w-1/3 pl-18 text-left">
              <span class="text-sm font-medium text-gray-100"
                >キーワード
                <i
                  class="pi pi-question-circle mr-1"
                  v-tippy="{
                    content:
                      'ハッシュタグ必須。ハッシュタグは１つのみ登録してください。',
                  }"
                ></i
                >*
              </span>
            </div>
            <div class="w-1/3 px-4 max-w-xl">
              <input
                class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                type="text"
                v-model="keyword"
                placeholder="#xxxx記念"
              />
            </div>
            <div class="w-1/4 px-4 max-w-xl">
              <DoButton
                :clickFunction="checkKeywordPromise"
                :values="{}"
                :disabled="!keyword || keyword_check"
                >キーワードチェック</DoButton
              >
            </div>
          </div>
          <div
            class="flex flex-wrap items-center -mx-4 pb-8 mb-8 border-b border-gray-400 border-opacity-20"
            v-if="condition_flag.keyword"
          >
            <div class="w-1/3 px-4 pb-4">
              <span class="text-sm font-medium text-gray-100"></span>
            </div>
            <div class="w-2/3 px-4">
              <div
                v-if="keyword_check_result"
                class="max-w-xl text-gray-100 text-left"
              >
                {{ keyword_check_result }}
              </div>
            </div>
          </div>
          <div
            class="flex flex-wrap items-center pb-8 mb-8 border-b border-gray-400 border-opacity-20"
            v-if="nouse_in_this_version"
          >
            <div class="w-1/3 pl-18 text-left">
              <span class="text-sm font-medium text-gray-100"
                >キャンペーン概要</span
              >
            </div>
            <div class="w-2/3 px-4 max-w-xl">
              <input
                class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                id="abstract"
                type="text"
                v-model="abstract"
                placeholder="概要：xxxのサービス開始1周年を記念したキャンペーン。総額yyyyyドル分のzzzzをポイントの高い順にプレゼント"
              />
            </div>
          </div>
          <div
            class="flex flex-wrap items-center pb-8 mb-8 border-b border-gray-400 border-opacity-20"
            v-if="condition_flag.detail"
          >
            <div class="w-1/3 pl-18 text-left">
              <span class="text-sm font-medium text-gray-100"
                >キャンペーン詳細</span
              >
            </div>
            <div class="w-2/3 px-4 max-w-xl">
              <textarea
                class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                rows="4"
                v-model="detail"
                placeholder="メモとしてご利用ください"
              />
            </div>
          </div>
          <div
            class="flex flex-wrap items-center pb-8 mb-8 border-b border-gray-400 border-opacity-20"
            v-if="condition_flag.start_time && condition_flag.end_time"
          >
            <div class="w-1/3 pl-18 text-left">
              <span class="text-sm font-medium text-gray-100"
                >キャンペーン期間 *
              </span>
            </div>
            <div class="w-full sm:w-2/3 px-4">
              <div class="max-w-xl flex">
                <div class="relative block px-3 w-full text-gray-100">
                  <Datepicker v-model="date" range :min-date="min_date" />
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex flex-wrap items-center pb-8 mb-8 border-b border-gray-400 border-opacity-20"
            v-if="!condition_flag.start_time && condition_flag.end_time"
          >
            <div class="w-1/3 pl-18 text-left">
              <span class="text-sm font-medium text-gray-100">募集期限 * </span>
            </div>
            <div class="w-2/3 px-4 max-w-xl flex -mx-3">
              <div class="relative block px-3 w-full text-gray-100">
                <Datepicker v-model="date[1]" :min-date="min_date" />
              </div>
            </div>
          </div>
          <div
            class="flex flex-wrap items-center pb-8 mb-8 border-b border-gray-400 border-opacity-20"
            v-if="condition_flag.amount"
          >
            <div class="w-1/3 pl-18 text-left">
              <span class="text-sm font-medium text-gray-100">当選人数 * </span>
            </div>
            <div class="w-1/3 px-4 max-w-xl">
              <input
                class="block py-4 px-3 w-full text-sm placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                v-bind:class="
                  giftDataExists == true ? 'text-gray-300' : 'text-gray-50'
                "
                type="number"
                v-model="reword_amount"
                :disabled="giftDataExists"
                placeholder="10"
              />
            </div>
          </div>
          <div
            class="flex flex-wrap items-center pb-8 mb-8 border-b border-gray-400 border-opacity-20"
            v-if="condition_flag.estimated_participants_count"
          >
            <div class="w-1/3 pl-18 text-left">
              <span class="text-sm font-medium text-gray-100"
                >想定参加人数
                <i
                  class="pi pi-question-circle mr-1"
                  v-tippy="{
                    content: '当選確率を決定するために利用します',
                  }"
                ></i
                >*
              </span>
            </div>
            <div class="w-1/3 px-4 max-w-xl">
              <input
                class="block py-4 px-3 w-full text-sm text-gray-50 placeholder-gray-300 font-medium outline-none bg-transparent border border-gray-400 hover:border-white focus:border-green-500 rounded-lg"
                type="number"
                v-model="estimated_participants_count"
                placeholder="10000"
              />
            </div>
          </div>
          <div
            class="flex flex-wrap items-center pb-8 mb-8 border-b border-gray-400 border-opacity-20"
            v-if="condition_flag.distribution"
          >
            <div class="w-1/3 pl-18 text-left">
              <span class="text-sm font-medium text-gray-100"
                >報酬の分配方法</span
              >
            </div>
            <div class="w-2/3 px-4 max-w-xl">
              <div
                class="relative block px-3 w-full text-sm font-medium border border-gray-400 hover:border-white focus-within:border-green-500 rounded-lg"
              >
                <span
                  class="absolute top-1/2 right-0 mr-5 transform -translate-y-1/2"
                >
                  <i class="pi pi-chevron-down"></i>
                </span>
                <select
                  class="w-full py-4 text-gray-50 placeholder-gray-50 appearance-none bg-transparent outline-none"
                  id="distribution_type"
                  v-model="distribution_type"
                >
                  <option class="bg-gray-500" value="1">
                    獲得ポイントに基づくルールベースの分配
                  </option>
                  <option class="bg-gray-500" value="2">
                    獲得ポイントに基づく抽選
                  </option>
                  <option class="bg-gray-500" value="3">
                    その他（要望に合わせて対応いたします）
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div v-if="category == 'HASHTAG'">
            <DoButton
              :clickFunction="patchCampaignPromise"
              :values="{}"
              :disabled="!filled"
              >変更</DoButton
            >
          </div>
          <div v-if="category == 'FOLLOW_AND_RETWEET'">
            <DoButton
              :clickFunction="patchCampaignPromise"
              :values="{}"
              :disabled="!follow_and_retweet_filled"
              >変更</DoButton
            >
          </div>
          <div v-if="category == 'INSTANT_WIN'">
            <DoButton
              :clickFunction="patchCampaignPromise"
              :values="{}"
              :disabled="!instant_win_filled"
              >変更</DoButton
            >
          </div>
        </RightColumnOutline>
      </div>
    </div>
  </div>
</template>

<script>
import SideVar from "@/components/SideVar.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import api from "../api/api.js";
import { ref } from "vue";
import { useRoute } from "vue-router";
import checks from "../assets/mixins/Checks";

export default {
  name: "Campaign_modify",
  mixins: [checks],
  components: {
    SideVar,
    Datepicker,
  },
  setup() {
    let date = ref();
    return {
      date,
    };
  },
  data() {
    return {
      campaign_name: null,
      category: null,
      keyword: null,
      abstract: null,
      detail: null,
      reword_amount: null,
      reword_currency: null,
      distribution_type: null,
      today: new Date(),
      start_date_db: new Date(),
      nouse_in_this_version: false,
      conditions_twitter_account: [],
      conditions_tweet: [],
      estimated_participants_count: null,
      giftDataExists: false,
    };
  },
  mounted() {
    const route = useRoute();
    api
      .getCampaign({ id: route.params.id })
      .then((response) => {
        this.campaign_name = response.data[0]["campaign_name"];
        this.category = response.data[0]["category"];
        this.keyword = response.data[0]["keyword"];
        this.abstract = response.data[0]["abstruct"];
        this.detail = response.data[0]["detail"];
        this.reword_amount = response.data[0]["amount"];
        this.reword_currency = response.data[0]["currency_id"];
        this.distribution_type = response.data[0]["distribution_type"];
        // JSTに変換
        let start_date = new Date(response.data[0]["start_time"]);
        start_date.setHours(start_date.getHours() + 9);
        let end_date = new Date(response.data[0]["end_time"]);
        end_date.setHours(end_date.getHours() + 9);
        this.date = [start_date, end_date];
        this.start_date_db = response.data[0]["start_time"];
        if (this.category == "HASHTAG") {
          this.keyword_check = true;
        }
        if (this.category == "INSTANT_WIN") {
          api
            .getInstantWin({ campaign_id: route.params.id })
            .then((response) => {
              console.log(response);
              this.estimated_participants_count =
                response.data[0].est_participant;
            });
        }
      })
      .catch((error) => {
        this.$toast.show("キャンペーンの取得に失敗しました", {
          type: "error",
          position: "top-right",
          duration: 5000,
        });
        console.log("error: ", error);
      });
    this.getGifts();
  },
  computed: {
    plan: function () {
      return this.$store.getters["subscribePlan"];
    },
    min_date() {
      const default_min = new Date(
        new Date().setHours(this.today.getHours() + 1)
      );
      if (default_min >= this.start_date_db) {
        return this.start_date_db;
      } else {
        return default_min;
      }
    },
    filled() {
      return (
        this.campaign_name &&
        this.keyword_check &&
        this.category &&
        this.keyword
      );
    },
    follow_and_retweet_filled() {
      return (
        this.campaign_name && this.category && this.reword_amount && this.date
        // conditionsの変更はmodifyからは行わない
      );
    },
    instant_win_filled() {
      return (
        this.campaign_name &&
        this.category &&
        this.reword_amount &&
        this.date &&
        // conditionsの変更はmodifyからは行わない
        this.estimated_participants_count
      );
    },
    condition_flag: function () {
      let return_dict = {
        keyword: false,
        abstruct: false,
        detail: false,
        distribution_type: false,
        amount: false,
        currency_id: false,
        start_time: false,
        end_time: false,
        twitter_account: false,
        twitter_url: false,
      };
      if (this.category == null) {
        return return_dict;
      } else if (this.category == "HASHTAG") {
        // init
        return_dict = {
          keyword: true,
          abstruct: true,
          detail: true,
          distribution_type: false,
          amount: false,
          currency_id: false,
          start_time: true,
          end_time: true,
          twitter_account: false,
          twitter_url: false,
        };
        return return_dict;
      } else if (this.category == "FOLLOW_AND_RETWEET") {
        // init
        return_dict = {
          keyword: false,
          abstruct: false,
          detail: false,
          distribution_type: false,
          amount: true,
          currency_id: false,
          start_time: false,
          end_time: true,
          twitter_account: false,
          twitter_url: false,
        };
        return return_dict;
      } else if (this.category == "INSTANT_WIN") {
        return_dict = {
          keyword: false,
          abstruct: false,
          detail: false,
          distribution_type: false,
          amount: true,
          estimated_participants_count: true,
          currency_id: false,
          start_time: true,
          end_time: true,
          twitter_account: false,
          twitter_url: false,
        };
        return return_dict;
      } else {
        return return_dict;
      }
    },
  },
  watch: {
    keyword(value) {
      this.keyword_check = false;
      this.keyword_check_result = "";
      if (value.indexOf("#")) {
        this.keyword = "#" + value;
      }
    },
    date(value) {
      if (value != null && this.category != "FOLLOW_AND_RETWEET") {
        var diffTime = value[1].getTime() - value[0].getTime();
        var diffDay = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        if (diffDay > 7) {
          this.date = null;
          this.$toast.show("キャンペーン期間は１週間以内で設定してください", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
        }
      }
    },
  },
  methods: {
    patchCampaignPromise: function () {
      return new Promise((resolve) => {
        api
          .updateCampaign({
            id: this.$route.params.id,
            keyword: this.keyword,
            start_time: this.date[0],
            end_time: this.date[1],
            campaign_name: this.campaign_name,
            distribution_type: this.distribution_type,
            amount: this.reword_amount,
            currency_id: this.reword_currency,
            category: this.category,
          })
          .then(() => {
            if (this.category == "INSTANT_WIN") {
              api
                .updateInstantWin({
                  campaign_id: this.$route.params.id,
                  total_win: this.reword_amount,
                  est_participant: this.estimated_participants_count,
                  remain_win: this.reword_amount,
                  prob: this.reword_amount / this.estimated_participants_count,
                })
                .then(() => {
                  this.$toast.show("キャンペーンの変更が完了しました", {
                    type: "success",
                    position: "top-right",
                    duration: 3000,
                  });
                  this.$router.replace("/campaigns");
                })
                .catch((error) => {
                  this.$toast.show("インスタントウィンの変更に失敗しました", {
                    type: "error",
                    position: "top-right",
                    duration: 5000,
                  });
                  console.log("error: ", error);
                });
            } else {
              this.$toast.show("キャンペーンの変更が完了しました", {
                type: "success",
                position: "top-right",
                duration: 3000,
              });
              this.$router.replace("/campaigns");
            }
            resolve("resolved");
          })
          .catch((error) => {
            this.$toast.show("キャンペーンの変更に失敗しました", {
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            console.log("error: ", error);
            resolve("rejected");
          });
      });
    },
    getGifts: function () {
      api
        .getGifts({
          campaign_id: this.$route.params.id,
        })
        .then((response) => {
          if (response["data"].length != 0) {
            this.giftDataExists = true;
            this.$toast.show(
              "ギフト登録後は当選人数変更はできません。\
               変更する場合はお手数ですがイベントの作り直しをお願いします。",
              {
                type: "info",
                position: "top-right",
                duration: 5000,
              }
            );
          }
        })
        .catch((error) => {
          this.$toast.show("ギフト情報の取得に失敗しました", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
          console.log("error: ", error);
        });
    },
  },
};
</script>
