<template>
  <div>
    <div class="block navbar-menu relative z-20">
      <nav
        class="fixed top-0 left-0 bottom-0 w-60 flex flex-col justify-between h-full py-8 px-4 bg-gray-700 overflow-auto"
      >
        <div class="mb-3">
          <a class="inline-block mb-9 w-24" href="#">
            <router-link to="/campaigns">
              <img
                class="h-24"
                src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/sociama_02@4x.png"
                alt=""
              />
            </router-link>
          </a>
          <p class="pl-3 pb-6 text-left text-gray-200 text-sm">
            アカウントランク：{{ plan }}
          </p>
          <p class="pl-3 pb-6 text-left text-gray-200 text-sm">
            アカウント名：{{ organizer_name }}
          </p>
          <ul>
            <li class="mb-2">
              <router-link to="/campaigns">
                <a
                  class="flex items-center p-3 rounded-xl"
                  :class="
                    focuspage == '/campaigns'
                      ? 'text-white bg-blue-500'
                      : 'text-gray-300 hover:bg-gray-800'
                  "
                  href="#"
                >
                  <span class="pi pi-book"> </span>
                  <span class="ml-4 text-sm font-semibold"
                    >キャンペーン一覧</span
                  >
                </a>
              </router-link>
            </li>
            <li class="mb-2">
              <router-link to="/create-campaign">
                <a
                  class="flex items-center p-3 rounded-xl"
                  :class="
                    focuspage == '/create-campaign'
                      ? 'text-white bg-blue-500'
                      : 'text-gray-300 hover:bg-gray-800'
                  "
                  href="#"
                >
                  <span class="pi pi-file-edit"> </span>
                  <span class="ml-4 text-sm font-semibold"
                    >キャンペーン登録</span
                  >
                </a>
              </router-link>
            </li>
            <li class="mb-2">
              <a
                class="flex items-center p-3 rounded-xl"
                :class="
                  focuspage == '/subscribe'
                    ? 'text-white bg-blue-500'
                    : 'text-gray-300 hover:bg-gray-800'
                "
                href="https://billing.stripe.com/p/login/28o4hE0XH4IIdeE3cc"
              >
                <span class="pi pi-money-bill"> </span>
                <span class="ml-4 text-sm font-semibold">購入履歴・領収書</span>
              </a>
            </li>
            <li class="mb-2">
              <router-link to="/setting/external-connect">
                <a
                  class="flex items-center p-3 rounded-xl"
                  :class="
                    focuspage == '/setting/external-connect'
                      ? 'text-white bg-blue-500'
                      : 'text-gray-300 hover:bg-gray-800'
                  "
                  href="#"
                >
                  <span class="pi pi-globe"> </span>
                  <span class="ml-4 text-sm font-semibold"
                    >外部サービス連携</span
                  >
                </a>
              </router-link>
            </li>
            <li class="" v-if="plan == 'GOLD'">
              <button
                class="flex items-center p-3 rounded-xl"
                @click="discordChange"
              >
                <img
                  class="w-4"
                  src="https://sociama-public.s3.ap-northeast-1.amazonaws.com/product-images/discord_icon_130958.png"
                  alt="discord_icon"
                />
                <span class="ml-4 text-sm font-semibold text-gray-300"
                  >Discord分析<i
                    v-if="discord_active"
                    class="text-xs mx-2 pi pi-chevron-up"
                  ></i
                  ><i v-else class="text-xs mx-2 pi pi-chevron-down"></i
                ></span>
              </button>
            </li>
            <li class="" v-if="discord_active">
              <router-link to="/discord/summary">
                <a
                  class="flex items-center p-3 rounded-xl"
                  :class="
                    focuspage == '/discord/summary'
                      ? 'text-white bg-blue-500'
                      : 'text-gray-300 hover:bg-gray-800'
                  "
                  href="#"
                >
                  <!-- <span class="pi pi-globe"> </span> -->
                  <span class="ml-8 text-sm font-semibold">サマリー</span>
                </a>
              </router-link>
            </li>
            <li class="" v-if="discord_active">
              <router-link to="/discord/channels">
                <a
                  class="flex items-center p-3 rounded-xl"
                  :class="
                    focuspage == '/discord/channels'
                      ? 'text-white bg-blue-500'
                      : 'text-gray-300 hover:bg-gray-800'
                  "
                  href="#"
                >
                  <!-- <span class="pi pi-globe"> </span> -->
                  <span class="ml-8 text-sm font-semibold">チャンネル</span>
                </a>
              </router-link>
            </li>
            <li class="" v-if="discord_active">
              <router-link to="/discord/members">
                <a
                  class="flex items-center p-3 rounded-xl"
                  :class="
                    focuspage == '/discord/members'
                      ? 'text-white bg-blue-500'
                      : 'text-gray-300 hover:bg-gray-800'
                  "
                  href="#"
                >
                  <!-- <span class="pi pi-globe"> </span> -->
                  <span class="ml-8 text-sm font-semibold">メンバー</span>
                </a>
              </router-link>
            </li>
            <!-- Super Account 用の新しいメニュー項目 -->
            <li v-if="isSuperAccount" class="mb-2">
              <router-link to="/quests">
                <a
                  class="flex items-center p-3 rounded-xl"
                  :class="
                    focuspage == '/quests'
                      ? 'text-white bg-blue-500'
                      : 'text-gray-300 hover:bg-gray-800'
                  "
                  href="#"
                >
                  <span class="pi pi-list"></span>
                  <span class="ml-4 text-sm font-semibold text-red-300"
                    >クエスト一覧</span
                  >
                </a>
              </router-link>
            </li>
          </ul>
        </div>
        <div>
          <div class="mb-2">
            <router-link to="/config">
              <a
                class="flex items-center p-3 rounded-xl"
                :class="
                  focuspage == '/config'
                    ? 'text-white bg-blue-500'
                    : 'text-white hover:bg-gray-800'
                "
                href="#"
                ><span class="pi pi-cog text-white"> </span>
                <span class="ml-4 text-sm font-semibold">設定</span></a
              >
            </router-link>
          </div>
          <div class="mt-auto flex text-left p-4 items-center">
            <span class="pi pi-question-circle text-white"> </span>
            <a
              href="https://quick-nebula-353.notion.site/d6913178b00640c2b5e9925d5682544d?pvs=4"
              class="ml-4 text-sm font-semibold text-white"
              target="_blank"
              rel="noopener noreferrer"
              >FAQ</a
            >
          </div>
          <div class="flex text-left p-4 items-center">
            <span class="pi pi-send text-white"> </span>
            <a
              href="https://quick-nebula-353.notion.site/3756f9c664a441c59d62a9ef92807acf"
              class="ml-4 text-sm font-semibold text-white"
              target="_blank"
              rel="noopener noreferrer"
              >お問い合わせ</a
            >
          </div>
          <div>
            <a
              class="flex items-center p-4 text-gray-300 hover:bg-gray-800 rounded-xl"
              href="#"
              @click="signOut"
            >
              <span class="pi pi-sign-out"> </span>
              <span class="ml-4 text-sm font-semibold">ログアウト</span>
            </a>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";
import "firebase/auth";
import api from "../api/api.js";
import store from "@/store";

export default {
  data() {
    return {
      organizer_name: "",
      isSuperAccount: false,
    };
  },
  mounted() {
    let tmp = this.$store.getters["subscribePlan"];
    if (tmp == null) {
      api
        .checkSubscribe()
        .then((response) => {
          store.commit("updateSubscribePlan", response["data"]);
        })
        .catch(() => {
          store.commit("updateSubscribePlan", "FREE");
        });
    }
    this.checkOrganizer();
  },
  computed: {
    plan: function () {
      return this.$store.getters["subscribePlan"];
    },
    focuspage: function () {
      return this.$route.path;
    },
    discord_active: function () {
      return this.$store.getters["discordMenuActive"];
    },
    small_campaign_count: function () {
      return this.$store.getters["smallCampaignCount"];
    },
    big_campaign_count: function () {
      return this.$store.getters["bigCampaignCount"];
    },
  },
  methods: {
    signOut() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          this.$toast.show("ログアウトしました", {
            type: "success",
            position: "top-right",
            duration: 5000,
          });
          this.$store.commit("updateUser", null);
          this.$router.replace("/login");
        })
        .catch((error) => {
          this.$toast.show("ログアウトに失敗しました", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
          console.log("signout error", error);
        });
    },
    discordChange() {
      this.$store.commit("updatediscordMenuActive", !this.discord_active);
    },
    checkOrganizer() {
      api
        .getMyOrganizer()
        .then((response) => {
          this.$store.commit(
            "updatesmallCampaignCount",
            response.data.small_campaign_count
          );
          this.$store.commit(
            "updatebigCampaignCount",
            response.data.big_campaign_count
          );
          this.organizer_name = response.data.name;
          this.isSuperAccount = this.organizer_name === "super account";
        })
        .catch((error) => {
          this.$toast.show("オーガナイザー情報の取得に失敗しました。", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
          console.log("organizer check error", error);
        });
    },
  },
};
</script>
