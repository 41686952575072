<template>
  <div class="container mx-auto p-4">
    <h1 class="text-2xl font-bold mb-4">新規クエスト作成</h1>
    <form @submit.prevent="createQuest" class="space-y-4">
      <div>
        <label for="name" class="block mb-1">名前</label>
        <input
          id="name"
          v-model="quest.name"
          type="text"
          class="w-full p-2 border rounded"
          required
        />
      </div>
      <div>
        <label for="description" class="block mb-1">説明</label>
        <textarea
          id="description"
          v-model="quest.description"
          class="w-full p-2 border rounded"
          rows="3"
        ></textarea>
      </div>
      <div>
        <label for="content_type" class="block mb-1">コンテンツタイプ</label>
        <select
          id="content_type"
          v-model="quest.content_type"
          class="w-full p-2 border rounded"
          required
          @change="updateQuestBasedOnContentType"
        >
          <option value="INSTANTWIN">INSTANTWIN</option>
          <option value="X_FOLLOW">X_FOLLOW</option>
          <option value="INSTAGRAM_FOLLOW">INSTAGRAM_FOLLOW</option>
          <option value="VOTING">VOTING</option>
          <option value="QUIZ">QUIZ</option>
        </select>
      </div>
      <div>
        <label for="frequency_type" class="block mb-1">頻度タイプ</label>
        <select
          id="frequency_type"
          v-model="quest.frequency_type"
          class="w-full p-2 border rounded"
          required
          :disabled="isFrequencyTypeDisabled"
        >
          <option value="ONCE">ONCE</option>
          <option value="UNLIMITED">UNLIMITED</option>
          <option value="DAILY">DAILY</option>
        </select>
      </div>
      <div>
        <label for="start_date" class="block mb-1">開始日時</label>
        <input
          id="start_date"
          v-model="quest.start_date"
          type="datetime-local"
          class="w-full p-2 border rounded"
          required
        />
      </div>
      <div>
        <label for="end_date" class="block mb-1">終了日時</label>
        <input
          id="end_date"
          v-model="quest.end_date"
          type="datetime-local"
          class="w-full p-2 border rounded"
          required
        />
      </div>
      <div>
        <label for="point_type" class="block mb-1">ポイントタイプ</label>
        <input
          id="point_type"
          v-model="quest.point_type"
          type="text"
          class="w-full p-2 border rounded"
          required
        />
      </div>
      <div v-if="showPointAmount">
        <label for="point_amount" class="block mb-1">ポイント量</label>
        <input
          id="point_amount"
          v-model.number="quest.point_amount"
          type="number"
          class="w-full p-2 border rounded"
          required
          :readonly="quest.content_type === 'VOTING'"
        />
      </div>
      <div v-if="showHeaderUrl">
        <label for="header_url" class="block mb-1">ヘッダーURL</label>
        <input
          id="header_url"
          v-model="quest.header_url"
          type="url"
          class="w-full p-2 border rounded"
        />
      </div>
      <div>
        <label class="flex items-center">
          <input v-model="quest.visible" type="checkbox" class="mr-2" />
          <span>表示</span>
        </label>
      </div>
      <div v-if="showExternalSiteUrl">
        <label for="external_site_url" class="block mb-1">外部サイトURL</label>
        <input
          id="external_site_url"
          v-model="quest.external_site_url"
          type="url"
          class="w-full p-2 border rounded"
        />
      </div>
      <div>
        <label for="priority" class="block mb-1">優先度</label>
        <input
          id="priority"
          v-model.number="quest.priority"
          type="number"
          class="w-full p-2 border rounded"
          required
        />
      </div>
      <div>
        <label for="available_count" class="block mb-1">利用可能回数</label>
        <input
          id="available_count"
          v-model.number="quest.available_count"
          type="number"
          class="w-full p-2 border rounded"
          required
        />
      </div>
      <div v-if="showVoteOptions">
        <h3 class="text-lg font-semibold mb-2">投票オプション</h3>
        <div v-for="(option, index) in voteOptions" :key="index" class="mb-2">
          <label :for="'option' + (index + 1)" class="block mb-1"
            >選択肢 {{ index + 1 }}</label
          >
          <input
            :id="'option' + (index + 1)"
            v-model="voteOptions[index]"
            type="text"
            class="w-full p-2 border rounded"
            :required="index < 2"
          />
        </div>
        <div>
          <label for="vote_description" class="block mb-1">説明</label>
          <textarea
            id="vote_description"
            v-model="voteDescription"
            class="w-full p-2 border rounded"
            rows="3"
          ></textarea>
        </div>
      </div>
      <div>
        <button
          type="submit"
          class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          :disabled="isLoading"
        >
          {{ isLoading ? "作成中..." : "クエストを作成" }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, inject, computed } from "vue";
import { useRouter } from "vue-router";
import api from "../api/api.js";

export default {
  setup() {
    const router = useRouter();
    const toast = inject("toast");
    const isLoading = ref(false);

    const quest = ref({
      name: "",
      description: "",
      content_type: "",
      frequency_type: "",
      start_date: "1990-01-01T00:00:00",
      end_date: "2099-01-01T00:00:00",
      point_type: "BASE",
      point_amount: 10,
      header_url: "",
      visible: true,
      external_site_url: "",
      priority: 100,
      available_count: 10000000,
    });

    const voteOptions = ref(["", "", "", ""]);
    const voteDescription = ref("");

    const showHeaderUrl = computed(() => {
      return !["X_FOLLOW", "INSTAGRAM_FOLLOW", "VOTING", "QUIZ"].includes(
        quest.value.content_type
      );
    });

    const showExternalSiteUrl = computed(() => {
      return !["VOTING", "QUIZ"].includes(quest.value.content_type);
    });

    const showPointAmount = computed(() => {
      return quest.value.content_type !== "QUIZ";
    });

    const showVoteOptions = computed(() => {
      return ["VOTING", "QUIZ"].includes(quest.value.content_type);
    });

    const isFrequencyTypeDisabled = computed(() => {
      return ["X_FOLLOW", "INSTAGRAM_FOLLOW"].includes(
        quest.value.content_type
      );
    });

    const updateQuestBasedOnContentType = () => {
      if (["X_FOLLOW", "INSTAGRAM_FOLLOW"].includes(quest.value.content_type)) {
        quest.value.frequency_type = "ONCE";
      }
      if (quest.value.content_type === "VOTING") {
        quest.value.point_amount = 20;
      }
      if (quest.value.content_type === "QUIZ") {
        quest.value.point_amount = 0;
      }
    };

    const createQuest = async () => {
      if (!validateQuest()) {
        return;
      }

      isLoading.value = true;
      try {
        const questData = { ...quest.value };

        // 外部URLの変換
        if (questData.content_type === "X_FOLLOW") {
          const url = new URL(questData.external_site_url);
          if (url.hostname !== "x.com" && url.hostname !== "twitter.com") {
            throw new Error("無効なXのURL");
          }
          const username = url.pathname.split("/")[1];
          if (!username) {
            throw new Error("無効なXのユーザー名");
          }
          questData.external_site_url = `twitter://user?screen_name=${username}`;
        } else if (questData.content_type === "INSTAGRAM_FOLLOW") {
          const url = new URL(questData.external_site_url);
          if (
            url.hostname !== "www.instagram.com" &&
            url.hostname !== "instagram.com"
          ) {
            throw new Error("無効なInstagramのURL");
          }
          const username = url.pathname.split("/")[1];
          if (!username) {
            throw new Error("無効なInstagramのユーザー名");
          }
          questData.external_site_url = `instagram://user?username=${username}`;
        } else if (questData.external_site_url) {
          // 他のコンテンツタイプでURLが入力されている場合
          const url = new URL(questData.external_site_url);
          if (
            url.hostname !== "x.com" &&
            url.hostname !== "twitter.com" &&
            url.hostname !== "www.instagram.com" &&
            url.hostname !== "instagram.com"
          ) {
            throw new Error("無効な外部サイトURL");
          }
        }
        // 固定値
        questData.owner_hub_user_id = 0;

        const response = await api.postQuest(questData);
        console.log("Created quest:", response);

        // VOTINGとQUIZの場合、投票オプションを別のAPIコールで登録
        if (["VOTING", "QUIZ"].includes(questData.content_type)) {
          const voteOptionData = {
            quest_id: response.data.quest_id,
            option1_text: voteOptions.value[0],
            option2_text: voteOptions.value[1],
            option3_text: voteOptions.value[2] || null,
            option4_text: voteOptions.value[3] || null,
            description: voteDescription.value || null,
          };

          try {
            const voteOptionResponse = await api.postVoteOptions(
              response.data.quest_id,
              voteOptionData
            );
            console.log("Created vote options:", voteOptionResponse);
          } catch (voteOptionError) {
            console.error(
              "投票オプションの作成に失敗しました:",
              voteOptionError
            );
            toast.error(
              "クエストは作成されましたが、投票オプションの作成に失敗しました",
              {
                position: "top-right",
                duration: 5000,
              }
            );
            // ここでクエストを削除するか、ユーザーに再試行を促すなどの対応を検討してください
          }
        }

        toast.success("クエストが正常に作成されました", {
          position: "top-right",
          duration: 5000,
        });
        router.push("/quests");
      } catch (error) {
        console.error("クエストの作成に失敗しました:", error);
        toast.error(error.message || "クエストの作成に失敗しました", {
          position: "top-right",
          duration: 5000,
        });
      } finally {
        isLoading.value = false;
      }
    };

    const validateQuest = () => {
      // 基本的なバリデーション
      if (
        !quest.value.name ||
        !quest.value.content_type ||
        !quest.value.frequency_type
      ) {
        toast.error("必須項目を入力してください", {
          position: "top-right",
          duration: 5000,
        });
        return false;
      }

      // 日付のバリデーション
      const startDate = new Date(quest.value.start_date);
      const endDate = new Date(quest.value.end_date);
      if (startDate >= endDate) {
        toast.error("終了日時は開始日時よりも後である必要があります", {
          position: "top-right",
          duration: 5000,
        });
        return false;
      }

      // 数値フィールドのバリデーション
      if (
        quest.value.point_amount < 0 ||
        quest.value.priority <= 0 ||
        quest.value.available_count <= 0
      ) {
        toast.error(
          "ポイント量、優先度、利用可能回数は正の数である必要があります",
          {
            position: "top-right",
            duration: 5000,
          }
        );
        return false;
      }

      // VOTINGとQUIZの場合、必須の選択肢をバリデーション
      if (["VOTING", "QUIZ"].includes(quest.value.content_type)) {
        if (!voteOptions.value[0] || !voteOptions.value[1]) {
          toast.error("選択肢1と選択肢2は必須です", {
            position: "top-right",
            duration: 5000,
          });
          return false;
        }
      }

      return true;
    };

    return {
      quest,
      isLoading,
      createQuest,
      showHeaderUrl,
      showExternalSiteUrl,
      showPointAmount,
      showVoteOptions,
      isFrequencyTypeDisabled,
      updateQuestBasedOnContentType,
      voteOptions,
      voteDescription,
    };
  },
};
</script>
