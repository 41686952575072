<template>
  <div class="container mx-auto p-4 text-sm">
    <h1 class="text-xl font-bold mb-4">クイズ管理</h1>

    <div v-if="loading">読み込み中...</div>
    <div v-if="error">エラー: {{ error }}</div>

    <div v-if="!loading && !error" class="mb-4">
      <h2 class="text-lg font-semibold mb-2">クイズリスト</h2>
      <div class="overflow-x-auto">
        <table class="min-w-full bg-white">
          <thead>
            <tr>
              <th class="px-3 py-2 text-xs">アクション</th>
              <th class="px-3 py-2 text-xs">ID</th>
              <th class="px-3 py-2 text-xs">名前</th>
              <th class="px-3 py-2 text-xs">開始日</th>
              <th class="px-3 py-2 text-xs">
                終了日
                <span class="text-gray-500">▲</span>
              </th>
              <th class="px-3 py-2 text-xs">状態</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="quiz in displayedQuizzes"
              :key="quiz.quests.quest_id"
              :class="{ 'bg-gray-200': quiz.vote_options.correct_id }"
            >
              <td class="px-3 py-2">
                <button
                  v-if="!quiz.vote_options.correct_id"
                  @click="handleQuizSelect(quiz.quests.quest_id)"
                  class="bg-blue-500 text-white px-2 py-1 rounded text-xs"
                >
                  選択
                </button>
                <button
                  v-else
                  @click="handleRevertAnswer(quiz.quests.quest_id)"
                  class="bg-red-500 text-white px-2 py-1 rounded text-xs"
                >
                  正解を取消
                </button>
              </td>
              <td class="px-3 py-2 text-xs">{{ quiz.quests.quest_id }}</td>
              <td class="px-3 py-2 text-xs">{{ quiz.quests.name }}</td>
              <td class="px-3 py-2 text-xs">
                {{ formatDate(quiz.quests.start_date) }}
              </td>
              <td class="px-3 py-2 text-xs">
                {{ formatDate(quiz.quests.end_date) }}
              </td>
              <td class="px-3 py-2 text-xs">
                {{ quiz.vote_options.correct_id ? "回答済み" : "未回答" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="selectedQuizId" class="mt-6">
      <button @click="deselectQuiz" class="mb-4 text-blue-500 underline">
        ← クイズリストに戻る
      </button>

      <h2 class="text-lg font-semibold mb-2">
        {{ selectedQuiz?.quests.name }}
      </h2>

      <div>
        <h3 class="text-base font-semibold mb-2">選択肢</h3>
        <div
          v-for="option in validOptions"
          :key="option.number"
          @click="handleOptionSelect(option.number)"
          :class="`p-2 mb-2 rounded cursor-pointer text-xs ${
            selectedOption === option.number ? 'bg-blue-200' : 'bg-gray-100'
          }`"
        >
          <span class="font-semibold">{{ option.text }}</span>
          <span class="ml-2 text-gray-600">
            ({{ calculateVotePercentage(option.number) }}% -
            {{ getTotalBetAmount(option.number) }}P)
          </span>
          <span class="ml-2 text-gray-500">
            投票数: {{ getVoteCount(option.number) }}
          </span>
        </div>
      </div>

      <button
        v-if="!selectedQuiz?.vote_options.correct_id"
        @click="handleConfirmAnswer"
        :disabled="selectedOption === null"
        :class="`mt-4 px-3 py-2 rounded text-xs ${
          selectedOption === null
            ? 'bg-gray-300 cursor-not-allowed'
            : 'bg-green-500 text-white'
        }`"
      >
        正解を確定
      </button>
    </div>

    <alert-dialog
      :show="showConfirmModal"
      :title="confirmModalTitle"
      @close="showConfirmModal = false"
      @confirm="confirmModalAction"
    >
      <template #description>
        <p class="text-sm text-gray-500">{{ confirmModalDescription }}</p>
      </template>
    </alert-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import api from "../api/api.js";
import AlertDialog from "./AlertDialog.vue";

const quizzes = ref([]);
const selectedQuizId = ref(null);
const selectedOption = ref(null);
const showConfirmModal = ref(false);
const loading = ref(false);
const error = ref(null);
const confirmModalTitle = ref("");
const confirmModalDescription = ref("");
const confirmModalAction = ref(() => {});

const selectedQuiz = computed(() => {
  return quizzes.value.find(
    (quiz) => quiz.quests.quest_id === selectedQuizId.value
  );
});

const displayedQuizzes = computed(() => {
  return selectedQuizId.value ? [selectedQuiz.value] : quizzes.value;
});

const validOptions = computed(() => {
  if (!selectedQuiz.value) return [];

  return selectedQuiz.value.vote_counts
    .map((option) => ({
      number: option.option_number,
      text: option.option_text,
    }))
    .filter((option) => option.text !== null && option.text !== undefined);
});

const fetchQuizzes = async () => {
  loading.value = true;
  error.value = null;
  try {
    const response = await api.fetchQuizzes();
    quizzes.value = response.data;
  } catch (err) {
    error.value = "クイズの取得に失敗しました";
    console.error("Error fetching quizzes:", err);
  } finally {
    loading.value = false;
  }
};

const fetchQuizDetails = async (questId) => {
  loading.value = true;
  error.value = null;
  try {
    const response = await api.getQuizDetails(questId);
    console.log(response.data);
    const index = quizzes.value.findIndex(
      (quiz) => quiz.quests.quest_id === questId
    );
    if (index !== -1) {
      quizzes.value[index] = response.data;
    } else {
      quizzes.value.push(response.data);
    }
    selectedQuizId.value = questId;
    selectedOption.value = null; // Reset selected option
  } catch (err) {
    error.value = "クイズ詳細の取得に失敗しました";
    console.error("Error fetching quiz details:", err);
  } finally {
    loading.value = false;
  }
};

const handleQuizSelect = (questId) => {
  fetchQuizDetails(questId);
};

const deselectQuiz = () => {
  selectedQuizId.value = null;
  selectedOption.value = null;
};

const handleOptionSelect = (optionNumber) => {
  selectedOption.value = optionNumber;
};

const handleConfirmAnswer = () => {
  confirmModalTitle.value = "正解の確認";
  confirmModalDescription.value = `${
    selectedQuiz.value.quests.name
  }\n選択肢: ${getSelectedOptionText()}\n本当によろしいですか？`;
  confirmModalAction.value = submitAnswer;
  showConfirmModal.value = true;
};

const handleRevertAnswer = (questId) => {
  const quiz = quizzes.value.find((q) => q.quests.quest_id === questId);
  confirmModalTitle.value = "正解の取消確認";
  confirmModalDescription.value = `${quiz.quests.name}の正解を取り消しますか？`;
  confirmModalAction.value = () => revertAnswer(questId);
  showConfirmModal.value = true;
};

const submitAnswer = async () => {
  loading.value = true;
  error.value = null;
  try {
    await api.confirmQuizAnswer(
      selectedQuiz.value.quests.quest_id,
      selectedOption.value
    );
    showConfirmModal.value = false;
    await fetchQuizDetails(selectedQuiz.value.quests.quest_id);
    await fetchQuizzes(); // リストを更新
    deselectQuiz();
  } catch (err) {
    error.value = "正解の確定に失敗しました";
    console.error("Error confirming answer:", err);
  } finally {
    loading.value = false;
  }
};

const revertAnswer = async (questId) => {
  loading.value = true;
  error.value = null;
  try {
    await api.revertQuizAnswer(questId);
    showConfirmModal.value = false;
    await fetchQuizzes(); // リストを更新
    if (selectedQuizId.value === questId) {
      await fetchQuizDetails(questId);
    }
  } catch (err) {
    error.value = "正解の取消に失敗しました";
    console.error("Error reverting answer:", err);
  } finally {
    loading.value = false;
  }
};

const getTotalBetAmount = (optionNumber) => {
  const option = selectedQuiz.value?.vote_counts.find(
    (opt) => opt.option_number === optionNumber
  );
  return option?.total_bet_amount ?? 0;
};

const getVoteCount = (optionNumber) => {
  const option = selectedQuiz.value?.vote_counts.find(
    (opt) => opt.option_number === optionNumber
  );
  return option?.vote_count ?? 0;
};

const calculateVotePercentage = (optionNumber) => {
  if (!selectedQuiz.value) return 0;

  const totalVotes = selectedQuiz.value.vote_counts.reduce((sum, option) => {
    return sum + (option.vote_count || 0);
  }, 0);

  if (totalVotes === 0) return 0;

  const optionVotes = getVoteCount(optionNumber);
  return Math.round((optionVotes / totalVotes) * 100);
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("ja-JP", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

const getSelectedOptionText = () => {
  if (!selectedQuiz.value || selectedOption.value === null) return "";
  const option = selectedQuiz.value.vote_counts.find(
    (opt) => opt.option_number === selectedOption.value
  );
  return option ? option.option_text : "";
};

onMounted(() => {
  fetchQuizzes();
});
</script>
